// Hooks
import { useEffect, useState, useRef } from 'react';

// Next
import { useRouter } from 'next/router';
import Link from 'next/link';

// GSAP
import { gsap, Power3 } from 'gsap';

// Analytics
import { analyticsClient } from '../../client/analytics';

// mobile flag
let mobile;

export const linkIsActive = (path, urls) => {
  const validate = Array.isArray(urls) ? urls.some((url) => path.includes(url)) : path === urls;
  return validate ? 'is-active' : '';
};

const Navigation = (props) => {
  const { modifier } = props;
  const router = useRouter();
  const [fixed, setFixed] = useState(false);

  // On desktop the offset y value when navigation should become fixed
  const offsetFixed = 25;

  // reference to desktop logos
  const refLogoTextDesktop = useRef();
  const refLogoRaccoonDesktop = useRef();

  // reference to mobile logos
  const refLogoTextMobile = useRef();
  const refLogoRaccoonMobile = useRef();

  // ref to get started link
  const getStartedMobileRef = useRef();
  const getStartedRef = useRef();

  useEffect(() => {
    const handleScroll = () => {
      if (!fixed && window.pageYOffset >= offsetFixed) {
        document.querySelector('body').classList.add('has-scrolled');
        setFixed(true);
      } else if ((fixed && window.pageYOffset <= offsetFixed) || !window.pageYOffset) {
        document.querySelector('body').classList.remove('has-scrolled');
        setFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return function cleanup() {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  // logo intro animation
  useEffect(() => {
    // timelinemax instance for intro animation
    const tl = gsap.timeline();

    // method for simple logo intro animation
    const simpleLogoIntro = () => {
      // simple intro animation
      if (mobile) {
        gsap.to(refLogoTextMobile.current, { duration: 0.6, opacity: 1 });
      } else {
        gsap.to(refLogoTextDesktop.current, { duration: 0.6, opacity: 1 });
      }
    };

    // check if mobile
    mobile = window.innerWidth < 1024;

    // determine the logo refs based on mobile or desktop
    const logoRaccoon = mobile ? refLogoRaccoonMobile.current : refLogoRaccoonDesktop.current;
    const logoText = mobile ? refLogoTextMobile.current : refLogoTextDesktop.current;

    if (mobile) {
      // reset opacity logo for mobile to 1
      gsap.set(refLogoTextDesktop.current, { opacity: 1 });
    } else {
      // reset opacity logo for desktop to 1
      gsap.set(refLogoTextMobile.current, { opacity: 1 });
    }

    // only do complex intro on homepage
    if (router.pathname === '/') {
      // only do complex intro once per session
      if (sessionStorage.getItem('mutiny_home') !== 'true') {
        // initial settings
        gsap.set(logoRaccoon, { scaleX: 2, color: '#AA00FF' });
        gsap.set(logoText, { scaleX: 2 });

        // start complex intro animation
        tl.to(logoRaccoon, { duration: 0.03, delay: 0.4, opacity: 1, ease: Power3.easeOut })
          .to(logoRaccoon, { duration: 0.02, opacity: 0, scaleX: 1 })
          .to(logoRaccoon, { duration: 0.06, opacity: 1, ease: Power3.easeOut })
          .to(logoRaccoon, { duration: 0.06, delay: 1, opacity: 0 })
          .to(logoRaccoon, { duration: 0.06, color: '#CFF6FF', opacity: 1 })
          .to(logoRaccoon, { duration: 0.06, opacity: 0 })
          .to(logoText, { duration: 0.06, opacity: 1, scaleX: 1 });
      } else {
        simpleLogoIntro();
      }
    } else {
      simpleLogoIntro();
    }

    analyticsClient.trackLink(getStartedMobileRef.current, '[Homepage] Navigation request invite click', {
      mobile: true,
    });
    analyticsClient.trackLink(getStartedRef.current, '[Homepage] Navigation request invite click', {
      mobile: false,
    });

    return () => {
      tl.clear();
      tl.kill();
    };
  }, []);

  return (
    <div className={`Navigation ${modifier || ''}`}>
      <div className="Navigation-mobile">
        <Link href="/">
          <a className="Navigation-link" aria-label="Go to Mutiny Homepage">
            <svg className="Navigation-logo Navigation--mobile" ref={refLogoTextMobile}>
              <use xlinkHref="#logo-mutiny-text" />
            </svg>
            <svg className="Navigation-logoRaccoon Navigation--mobile" ref={refLogoRaccoonMobile}>
              <use xlinkHref="#logo-mutiny-raccoon-inverted" />
            </svg>
          </a>
        </Link>

        <div>
          <a
            href={`${process.env.MUTINY_APP_URL}/login`}
            className="Navigation-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            Login
          </a>

          <Link href="/request-invite">
            <a className="Navigation-link Navigation--invite" ref={getStartedMobileRef}>
              Request demo
            </a>
          </Link>
        </div>
      </div>

      <Link href="/">
        <a className="Navigation-link Navigation-link--home" aria-label="Go to Mutiny Homepage">
          <svg className="Navigation-logo Navigation--desktop" ref={refLogoTextDesktop}>
            <use xlinkHref="#logo-mutiny-text" />
          </svg>
          <svg className="Navigation-logoRaccoon Navigation--desktop" ref={refLogoRaccoonDesktop}>
            <use xlinkHref="#logo-mutiny-raccoon-inverted" />
          </svg>
        </a>
      </Link>

      <div className="Navigation-anchors">
        <Link href="/product">
          <a className={`Navigation-link ${linkIsActive(router.asPath, '/product')}`}>
            <span className="Navigation-label">Product</span>
          </a>
        </Link>
        <Link href="/cases">
          <a className={`Navigation-link ${linkIsActive(router.asPath, ['/cases', '/cases/'])}`}>
            <span className="Navigation-label Navigation-label--short">Cases</span>
            <span className="Navigation-label Navigation-label--full">Case studies</span>
          </a>
        </Link>
        <Link href="/careers">
          <a className={`Navigation-link ${linkIsActive(router.asPath, ['/careers'])}`}>
            <span className="Navigation-label">Team</span>
          </a>
        </Link>
        {!process.env.HIDE_CSV && (
          <Link href="/community">
            <a className={`Navigation-link ${linkIsActive(router.asPath, '/community')}`}>
              <span className="Navigation-label">Community</span>
            </a>
          </Link>
        )}
      </div>

      <div className="Navigation-app">
        <a
          href={`${process.env.MUTINY_APP_URL}/login`}
          className="Navigation-link Navigation--login"
          target="_blank"
          rel="noopener noreferrer"
        >
          Login
        </a>

        <Link href="/request-invite">
          <a className="Navigation-link Navigation--invite" href="/request-invite" ref={getStartedRef}>
            Request demo
          </a>
        </Link>
      </div>
    </div>
  );
};

export default Navigation;
