import { INLINES } from '@contentful/rich-text-types';

export const RENDER_DOCUMENT_OPTIONS = {
  renderNode: {
    [INLINES.ENTRY_HYPERLINK]: (node, children) => {
      const referencedEntry = node.data.target;
      const contentType = referencedEntry.sys.contentType.sys.id;
      if (contentType === 'playbook') {
        return <a href={`/playbooks/${referencedEntry.fields.slug}`}>{children}</a>;
      }
      if (contentType === 'case') {
        return <a href={`/cases/${referencedEntry.fields.slug}`}>{children}</a>;
      }
      return children;
    },
  },
};

export const RENDER_DOCUMENT_OPTIONS_WITH_LINE_BREAKS = {
  renderText: (text) =>
    text
      .split('\n')
      .map((chunk, i) => [
        i > 0 && <br key={`br--${i}`} />,
        chunk.length > 0 && <span key={`span--${i}`}>{chunk}</span>,
      ]),
};

export const orDefaultPriority = (priority) => {
  if (priority === 0) {
    return priority;
  }
  return priority || Number.MAX_SAFE_INTEGER;
};

export const imageLoader = ({ src, width, quality }) => `${src}?w=${width}&q=${quality || 75}`;
