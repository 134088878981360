// Hooks
import { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';

// Next
import Link from 'next/link';

// GSAP
import { gsap, Power3 } from 'gsap';
import { DrawSVGPlugin } from '../../libs/DrawSVGPlugin'; // eslint-disable-line no-unused-vars

gsap.registerPlugin(DrawSVGPlugin);

const Button = (props) => {
  const { label, icon, url, modifier, onClick, skipAnimation, target, custom } = props;

  // construct button style
  const buttonStyle = modifier ? `Button ${modifier}` : 'Button';

  // determine if this button has a stroke based on modifier name
  const stroke = modifier.substr(modifier.length - 4, 4) !== 'Fill';

  // ref to button outline
  const buttonOutline = useRef();

  // intersection observer
  const [buttonWrapper, inView] = useInView({
    rootMargin: '0px 0px -50px 0px',
    triggerOnce: true,
  });

  useEffect(() => {
    let duration = 2;
    let delay = 0.1;
    if (skipAnimation) {
      duration = 0;
      delay = 0;
    }
    if (stroke && inView && buttonOutline.current.style.visibility !== 'visible') {
      // TODO temp fix for having this work for multiple button instances
      // animate outline stroke of button when in view
      buttonOutline.current.style.visibility = 'visible';

      gsap.from(buttonOutline.current, { duration, delay, drawSVG: '0%', ease: Power3.easeOut });
    }
  }, [inView]);

  useEffect(
    () => () => {
      gsap.killTweensOf(buttonOutline.current);
    },
    [],
  );

  const content = (
    <span className={buttonStyle} ref={buttonWrapper} onClick={onClick}>
      <svg className="Button-shape" width="100%" height="100%">
        <rect className="Button-bg" width="100%" height="50" />
        <rect
          className={inView ? 'Button-outline is-visible' : 'Button-outline'}
          ref={buttonOutline}
          width="100%"
          height="50"
        />
      </svg>
      <span className={`Button-label ${icon ? 'Button-label--withIcon' : ''}`}>
        <span className="Button-labelText">{label}</span>
        {icon && (
          <svg className="Button-labelIcon">
            <use xlinkHref={`#${icon}`} />
          </svg>
        )}
      </span>
    </span>
  );

  // exception for PDF donwload button
  if (custom) {
    return (
      <a className="Button-link" href={url}>
        {content}
      </a>
    );
  }

  if (url && target) {
    return (
      <Link href={url}>
        <a className="Button-link" target={target}>
          {content}
        </a>
      </Link>
    );
  }

  if (!url && !target) {
    return content;
  }

  return (
    <Link href={url}>
      <a className="Button-link">{content}</a>
    </Link>
  );
};

export default Button;
