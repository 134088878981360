// Hooks
import { useRef, useEffect } from 'react';

// Next
import Link from 'next/link';

// Analytics
import { analyticsClient } from '../../client/analytics';

const Footer = () => {
  const blogRef = useRef();
  const jobsRef = useRef();
  const easterEggRef = useRef();
  const easterEggMobileRef = useRef();

  useEffect(() => {
    analyticsClient.trackLink(blogRef.current, 'Blog link clicked');
    analyticsClient.trackLink(jobsRef.current, 'Jobs link clicked');
    analyticsClient.trackLink(easterEggRef.current, 'Logo easter egg clicked');
    analyticsClient.trackLink(easterEggMobileRef.current, 'Logo easter egg clicked (mobile)');
  }, []);

  return (
    <div className="Footer">
      <div className="Footer-inner">
        <div className="Footer-columns">
          <div className="Footer-column ">
            <a
              target="_blank"
              href="https://www.youtube.com/watch?v=eesxH2-8Jlo"
              rel="noopener noreferrer"
              aria-label="See Mutiny Easter Egg Video"
              ref={easterEggRef}
            >
              <svg className="Footer-logo">
                <use xlinkHref="#logo-mutiny-raccoon" />
              </svg>
            </a>
          </div>
          <div className="Footer-column">
            <Link href="/product">
              <a className="Footer-link">Product</a>
            </Link>
            <Link href="/cases">
              <a className="Footer-link">Case Studies</a>
            </Link>
            <Link href="/playbooks">
              <a className="Footer-link">Playbooks</a>
            </Link>
          </div>
          <div className="Footer-column">
            {!process.env.HIDE_CSV && (
              <Link href="/community">
                <a className="Footer-link">
                  <span className="Footer-link">Community</span>
                </a>
              </Link>
            )}
            <a
              href="https://www.dropbox.com/sh/tvdy7vgibbc1x6j/AAAyk0SW2G9CHGvdlMz-uHi0a?dl=0"
              className="Footer-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              Press kit
            </a>
            <Link href="/careers">
              <a className="Footer-link">Team</a>
            </Link>
          </div>
          <div className="Footer-column">
            <Link href="/privacy">
              <a className="Footer-link">Privacy policy</a>
            </Link>
            <Link href="/terms">
              <a className="Footer-link">Legal terms of services</a>
            </Link>
          </div>
        </div>
        <div className="Footer-disclaimer">
          <div className="Footer-disclaimerInner">
            <a
              target="_blank"
              href="https://www.youtube.com/watch?v=eesxH2-8Jlo"
              rel="noopener noreferrer"
              ref={easterEggMobileRef}
              aria-label="See Mutiny Easter Egg Video"
            >
              <svg className="Footer-disclaimerLogo">
                <use xlinkHref="#logo-mutiny-raccoon" />
              </svg>
            </a>
            <p>Not your grandma’s website personalization</p>
            <p>© Mutiny {new Date().getFullYear()}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
